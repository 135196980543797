<template>
  <div class="stories-table">
    <div class="points-limit-container">
      <div class="points-limit-title">
        <span class="points-limit">
          Points limit
        </span>
        <span
          v-if="pokerSession.options.pointsLimit.show || organizer"
          :class="counterWarning ? 'points-limit-counter-warning' : 'points-limit-counter'"
        >
          {{ totalTasksPoints }}/{{ pokerSession.options.pointsLimit.points }}
        </span>
        <span
          v-if="!pokerSession.options.pointsLimit.show && !organizer"
          class="points-limit-counter"
        >
          --/--
        </span>
      </div>
      <div class="points-limit-content">
        <div class="story-head">
          STORY #
        </div>
        <div
          v-if="pokerSession.activeTask"
          class="story-number"
        >
          {{ activeTaskNumber }} / {{ pokerSession.tasks.length }}
        </div>
        <div
          v-if="!pokerSession.activeTask"
          class="story-number"
        >
          -- / {{ pokerSession.tasks.length }}
        </div>
      </div>
    </div>
    <div class="story-container">
      <div class="story-title">
        <span class="story">
          Story
        </span>
        <span class="store">
          Score
        </span>
      </div>
      <div class="story-content">
        <task-list
          :poker-session="pokerSession"
          @remove-task="removeTask"
          @reset-votes="resetVotes"
        />
      </div>
    </div>
    <div
      v-if="organizer"
      class="buttons"
    >
      <div class="buttons-first-row">
        <el-button
          type="success"
          plain
          class="add-story-btn"
          @click="tasksFilterVisible = true"
        >
          Add Story
        </el-button>
        <add-new-task
          :tasks-filter-visible="tasksFilterVisible"
          :poker-session="pokerSession"
          :repository="repository"
          @close-tasks-filter="closeTasksFilter"
          @pass-tasks="addTasks"
        />
      </div>
      <div class="buttons-second-row">
        <el-button
          v-if="pokerSession.exists()"
          type="danger"
          class="delete-session-btn"
          @click="remove"
        >
          Delete Session
        </el-button>
        <el-button
          v-if="!pokerSession.exists()"
          type="danger"
          class="delete-session-btn"
          @click="cancel"
        >
          Cancel
        </el-button>
        <el-button
          v-if="!pokerSession.exists()"
          type="primary"
          plain
          class="play-btn"
          @click="save"
        >
          Save
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { TaskRepository } from '@timeragent/core';
import { mapGetters } from 'vuex';

export default {
  name: 'StoriesTable',
  components: {
    TaskList: () => import('./TaskList.vue'),
    AddNewTask: () => import('./AddNewTask.vue'),
  },
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      taskRepository: new TaskRepository(),
      repository: new TaskRepository(),
      tasksFilterVisible: false,
      columns: {
        description: {
          title: 'Description',
          width: '3fr',
        },
        project: {
          title: 'Project',
          width: '2fr',
        },
        user: {
          title: 'User',
          width: '2fr',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    activeTaskNumber() {
      if (this.pokerSession.exists() && this.pokerSession.activeTask) {
        return this.pokerSession.tasks.findIndex(el => el.uuid === this.pokerSession.activeTask.uuid) + 1;
      }

      return 0;
    },
    totalTasksPoints() {
      const initialValue = 0;

      return this.pokerSession.tasks.reduce((accumulator, currentValue) => accumulator + currentValue.score,
        initialValue);
    },
    organizer() {
      if (this.pokerSession.exists()) {
        return this.pokerSession.user.uuid === this.user.uuid;
      }

      return true;
    },
    counterWarning() {
      return this.totalTasksPoints >= this.pokerSession.options.pointsLimit.points;
    },
  },
  async created() {
    await this.taskRepository.many({
      filters: {
        organizationUuid: this.$route.params.organizationUuid,
        tasksFilters: {
          dateRange: ['1996-10-15T00:05:32.000Z', '2099-08-14T00:05:32.000Z'],
        },
      },
    });
    if (this.pokerSession.tasks.length) {
      this.repository.dataset = this.taskRepository.dataset.filter(task => !this.pokerSession.tasks.map(u => u.uuid).includes(task.uuid));
    } else {
      this.repository.dataset = this.taskRepository.dataset;
    }
  },
  methods: {
    closeTasksFilter() {
      this.tasksFilterVisible = !this.tasksFilterVisible;
    },
    save() {
      this.$emit('save-session');
    },
    remove() {
      this.$emit('delete-session');
    },
    cancel() {
      this.$router.go(-1);
    },
    async addTasks(chosenTasks) {
      await this.pokerSession.tasks.push(...chosenTasks);
      this.repository.dataset = this.taskRepository.dataset.filter(task => !this.pokerSession.tasks.map(u => u.uuid).includes(task.uuid));
      this.$emit('save-tasks');
    },
    removeTask() {
      this.repository.dataset = this.taskRepository.dataset.filter(task => !this.pokerSession.tasks.map(u => u.uuid).includes(task.uuid));
    },
    resetVotes() {
      this.$emit('reset-votes');
    },
  },
};
</script>

<style scoped>
  .stories-table {
    display: grid;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    grid-template-rows: 80px 410px 110px;
    justify-items: stretch;
  }
  .points-limit-title {
    height: 20px;
    color: #909399;
    background: #f4f4f5;
    border: 1px solid #d3d4d6;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .points-limit-content {
    height: 58px;
    display: grid;
    justify-items: center;
    align-items: center;
  }
  .points-limit-counter-warning {
    color: red;
  }
  .story-head {
    font-size: 12px;
  }
  .story-number {
    font-size: 20px;
  }
  .story-title {
    height: 20px;
    color: #909399;
    background: #f4f4f5;
    border: 1px solid #d3d4d6;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .buttons {
    padding: 10px;
    display: grid;
    grid-template-rows: 55% 35%;
    align-content: space-between;
    background: #f4f4f5;
    border: 1px solid #d3d4d6;
    border-radius: 0 0 4px 4px;
  }
  .buttons-first-row {
    display: flex;
  }
  .buttons-second-row {
    display: flex;
    /*justify-content: space-between;*/
  }
  .add-story-btn {
    flex-grow: 1;
    align-self: stretch;
  }
  .delete-session-btn {
    flex-grow: 1;
    padding: 0;
  }
  .play-btn {
    flex-grow: 3;
    padding: 0;
  }
</style>
